<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.room_id" id="room_id" name="room_id" class="form-control form-control-sm">
                <option selected :value="''">Select a room</option>
                <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{ room.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2" v-if="room_bookings.total > 0">
          <div class="col-md-12 float-right">
            <div class="float-right">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Room</th>
              <th>Requested Date</th>
              <th>Time Slot</th>
              <th>Room Booking No.</th>
              <th>Food Expense</th>
              <th>Department</th>
              <th>Created At</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(room_booking, i) in room_bookings.data" :key="i">
              <td>{{ room_bookings.from + i}}</td>
              <td>{{ room_booking.room.name }}</td>
              <td>{{ room_booking.booking_date }}</td>
              <td>
                <span v-for="(room_booking_time_slot, index) in room_booking.room_booking_time_slots" :key="index">
                    {{ room_booking_time_slot.time_slot_rule.time_slot }} <br/>
                  </span>
              </td>
              <td>{{ room_booking.room_booking_no }}</td>
              <td>{{ room_booking.room_booking_entertainments_sum_total_price }}</td>
              <td>{{ room_booking.department.department_name }}</td>
              <td>{{ customDate(room_booking.created_at) }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="room_bookings.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <div class="float-right">
            <pagination class="mt-2" :data="room_bookings" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      rooms: [],
      room_bookings: {},
      loading: false,
      btnLoading: false,
      loader: false,
      flag: false,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        room_id: '',
        date_range: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/room/codes')
        .then(response => {
          const data = response.data
          this.rooms = data.rooms
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/report/rooms/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.room_bookings = response.data.room_bookings
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.flag = true
      this.loading = true
      apiClient.post('api/report/rooms/search?page=' + page, this.search_data)
        .then(response => {
          this.loading = false
          this.flag = true
          this.room_bookings = response.data.room_bookings
        })
    },
    excelDownload() {
      this.excelDownloadLoader = true
      apiClient.post('api/report/rooms/excel-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'room_bookings.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
